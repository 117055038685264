//@flow

import React, { useEffect } from "react";
import PromoStickyBarV1 from "./PromoStickyBarV1";
import PromoStickyBarV2 from "./PromoStickyBarV2";
import { rcookie } from "../utils";

const PromoStickyBar = (props) => {
  
  useEffect(() => {
    props.sendAnalyticsEvent({
      category: "stickybar",
      action: "show",
      label: props.label,
      os: 4
    }, {
      channelid: props.channelid
    });
  }, [])

  const handleStickyBarActionBtnClick = () => {
    props.sendAnalyticsEvent({
      category: "stickybar",
      action: "click",
      label: props.label,
      os: 4
    }, {
      channelid: props.channelid
    })
  }

  // By default refer to the old one -> v1.
  let PromoStickyBarContent = PromoStickyBarV1;
  
  if (props.version && props.version === 2) {
    PromoStickyBarContent = PromoStickyBarV2;
  }
  
  return <PromoStickyBarContent {...props} onActionBtnClick={handleStickyBarActionBtnClick} />;
}

export default PromoStickyBar;